// libs
//import { useNavigate } from "react-router-dom";
//import { routes } from "../../../utils/routes";

// assets
import styles from "./Benefits.module.scss";

export const Benefits = ({ benefits, showModal = () => {}, afterReleaseAt }) => {
  //const navigate = useNavigate();

  return (
    <div className={styles.benefits}>
      <ul>
        {benefits.map((benefit) => (
          <li>
            <img src={benefit.icon} alt='' />
            {benefit.title}
          </li>
        ))}
      </ul>
      <button
        className={"btn btn-yellow"}
        onClick={() => {showModal()}}>
        {afterReleaseAt ? <>buy <span>now</span></> : <>join the <span>waitlist</span> </> }
      </button>
    </div>
  )
}