import React, { useState } from 'react';

// libs
import { useNavigate } from "react-router-dom";

// components
//import { VideoModal } from "../../modals";
import { Benefits, Countdown, Layout } from "../../common";
import { routes } from "../../../utils/routes";

import SignInModal from "../../modals/SignInModal/SignInModal";

// assets
import styles from './Kayoralpha.module.scss';
//import playBtn from "../../../images/icons/play.svg";
import background from "../../../images/kayoralpha-banner.jpg";
import user from "../../../images/icons/user.svg";
import mp3 from "../../../images/icons/mp3.svg";
import money from "../../../images/icons/money.svg";


const benefits = [
  { title: 'A unique numbered exclusive image just for you', icon: user },
  { title: 'A MP3 download of the song to keep', icon: mp3 },
  { title: 'A share of the streaming revenues from ‘Origins’ forever!', icon: money },
]

function lastSunday(month, year) {
  const d = new Date();
  const lastDayOfMonth = new Date(Date.UTC(year || d.getFullYear(), month+1, 0));
  const day = lastDayOfMonth.getDay();
  return new Date(Date.UTC(lastDayOfMonth.getFullYear(), lastDayOfMonth.getMonth(), lastDayOfMonth.getDate() - day));
}

function isBST(date) {
  const d = date || new Date();
  const starts = lastSunday(2, d.getFullYear());
  starts.setHours(1);
  const ends = lastSunday(9, d.getFullYear());
  starts.setHours(1);
  return d.getTime() >= starts.getTime() && d.getTime() < ends.getTime();
}


export function checkLive(date_string) {

  const str_date = date_string

  const year = parseInt(str_date.substring(0, 4));
  const month = parseInt(str_date.substring(5, 7)) - 1;
  const day = parseInt(str_date.substring(8, 10));
  
  let hour = parseInt(str_date.substring(11, 13));
  //const hour = parseInt(str_date.substring(11, 13));
  const minute = parseInt(str_date.substring(14, 16));
  const second = parseInt(str_date.substring(17, 19));

  const date_ = new Date(Date.UTC(year, month, day, hour, minute, second))

  if (isBST(date_)) {
    hour -= 1
  }

  const date = new Date(Date.UTC(year, month, day, hour, minute, second)).getTime()

  const now = Date.now()

  return {
    date_epoch: date,
    now_epoch: now
  }

}

export const Kayoralpha = () => {
  const navigate = useNavigate();

  //const [showVideo, setShowVideo] = useState(false);
  const [showSignInModal, setShowSignInModal] = useState(false);


  const str_date = process.env.REACT_APP_RELEASE_AT;

  const is_live = checkLive(str_date);

  const afterReleaseAt = is_live.now_epoch > is_live.date_epoch

  return (
    <Layout title='Origins - KayOrAlpha on SongBits' background={background} noLogo>
      {/*showVideo && <VideoModal setShowVideo={setShowVideo} src={'https://www.youtube.com/embed/JSfEUwFRtlA'} />*/}
      <div className={styles.kayoralpha}>
        <div className={styles.title}>
          <div className={styles.black}>
            <p>ownership release</p>
          </div>
          <h1>KAYORALPHA</h1>
          <p className={styles.drop}>‘Origins’</p>
        </div>
        <div className={styles.content}>
          <div className={styles.left}>
            <p>
              Grab your chance to share in the success of KayOrAlpha’s latest
              song <span className={styles.bold}>‘Origins’</span> exclusively for fans {afterReleaseAt ? <>released</> : <>releasing</>} on
              2nd June 2023 5pm (BST/GMT+1)...
            </p>
            <p>
              <span>How?</span> Join the waitlist below to receive your invite to buy a bit of the song and receive a
              share of the song’s streaming royalties for life plus exclusive VIP benefits!
            </p>
            {afterReleaseAt ? <></> : <>
            <p>
              Join the waitlist now below...
            </p>
            </>
            }
            <button
              className={"btn btn-yellow"}
              onClick={() => {
                if (afterReleaseAt) {
                  setShowSignInModal(true)
                } else {
                  navigate(routes.joinWaitList)
                }
              }}>
                {afterReleaseAt ? <>buy <span>now</span></> : <>join the <span>waitlist</span> </> }
            </button>
            <p>
              <span>Who said VIP?</span> All purchases come with a package of VIP benefits including
            </p>
            <Benefits benefits={benefits} afterReleaseAt={afterReleaseAt} showModal={() => {
                if (afterReleaseAt) {
                  setShowSignInModal(true)
                } else {
                  navigate(routes.joinWaitList)
                }
              }} />
          </div>
          <div className={styles.right}>
            <p className={styles.yellow}>COUNTDOWN TO SALE </p>
            <div className={styles.timer}>
              <Countdown time={is_live.date_epoch} />
            </div>
            {/*<div className={styles.play} onClick={() => setShowVideo(true)}>
              <img src={playBtn} alt="" />
            </div>*/}
          </div>
        </div>
      </div>
      <SignInModal
        show={showSignInModal}
        onClose={() => {
          setShowSignInModal(false)
        }}
        onCreateAccount={() => { 
          window.location.href = 'https://www.songbits.com/create-account?artist=' + process.env.REACT_APP_ARTIST_ID
         }}
        onAlreadyHaveAccount={() => { 
          window.location.href = 'https://www.songbits.com/kayoralpha/origins'
        }}
      />

    </Layout>
  )
}