import { useCountdown } from "../../../utils/useCountdown";

// assets
import styles from './Countdown.module.scss'

export const Countdown = ({ time }) => {
  const [days, hours, minutes, seconds] = useCountdown(time);

  return (
    <p className={styles.countdown}>
      {days > 0 ? days : 0}d {hours > 0 ? hours : 0}h {minutes > 0 ? minutes : 0}m <span>{seconds > 0 ? seconds : 0}</span>s
    </p>
  )
}