import React from "react";

// library
import { BrowserRouter, Routes, Route } from "react-router-dom";

// components
//import { Home } from "../../pages/Home/Home";
import { JoinWaitList } from "../../pages/JoinWaitList/JoinWaitList";
import { JoinWaitListThanks } from "../../pages/JoinWaitListThanks/JoinWaitListThanks";
import { routes } from "../../../utils/routes";
import { Kayoralpha } from "../../pages/Kayoralpha/Kayoralpha";
//import { LogoLoader } from "../LogoLoader/LogoLoader";

export const App = () => {
  return (
    <>
    {/*<LogoLoader/>*/}
    <BrowserRouter>
      <Routes>
        <Route path={routes.home} element={<Kayoralpha />}>
          <Route index element={<Kayoralpha />} />
        </Route>
        <Route path={routes.joinWaitList} element={<JoinWaitList />} />
        <Route
          path={routes.joinWaitListThanks}
          element={<JoinWaitListThanks />}
        />
      </Routes>
    </BrowserRouter>
    </>
  );
};
