import React from 'react';

// libs
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

// components
import { Footer } from "../Footer/Footer";
import { routes } from "../../../utils/routes";

// assets
import styles from "./Layout.module.scss";
import songbits from "../../../images/icons/songbits-without-text.svg";

export const Layout = ({ noLogo, background, title, children }) => {
  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <section className={styles.layout}>
        <img className={styles.background} src={background} alt="background" />
        <div className='container'>
          {!noLogo && <Link to={routes.home} className={styles.logo}>
            <img src={songbits} alt='' />
          </Link>}
          {children}
        </div>
        <Footer />
      </section>
    </>
  )
}